import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {faUser as fasUser} from "@fortawesome/free-solid-svg-icons";
import {faTicket as fasTicket} from "@fortawesome/free-solid-svg-icons";
import {faSpinner as fasSpinner} from "@fortawesome/free-solid-svg-icons";

export const icons = [
    fasUser,
    fasTicket,
    fasSpinner,
];

library.add(...icons);

export const fontawesome = {
    install (app) {
        app.component('font-awesome-icon', FontAwesomeIcon);
        app.component('Fa', FontAwesomeIcon);
    },
};
