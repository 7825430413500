import './bootstrap';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import { i18nVue } from 'laravel-vue-i18n'
import { globals } from './App/globals.js';
import {fontawesome} from "./App/fontawesome";
import {qrcode} from "./App/qrcode";
// import { Ziggy } from './App/Ziggy.js';
import * as Sentry from "@sentry/vue";

import '@/App/echo';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })

        const sentryDns = import.meta.env.VITE_SENTRY_DSN_PUBLIC
        Sentry.init({
            app,
            dsn: sentryDns,
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.0,
            replaysOnErrorSampleRate: 1.0,
        });


        app.use(plugin)
            .use(i18nVue, {
                resolve: async lang => {
                    const langs = import.meta.glob('../../lang/*.json');
                    return await langs[`../../lang/${lang}.json`]();
                }
            })
            .use(globals)
            .use(qrcode)
            .use(fontawesome)
            .use(ZiggyVue, Ziggy)
            .mount(el);

        return app;
    },
});
import.meta.glob([
    '../images/**',
    '../fonts/**',
]);




InertiaProgress.init({ color: '#4B5563' });
